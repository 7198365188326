import React from 'react';
import { Link } from 'react-router-dom';
import Config from '../../config';
import PropTypes from 'prop-types';
import ShareButton from './ShareButton';
import HtmlUtils from '../../common/htmlutils';
import LocaleUtils from '../../common/LocaleUtils';
import URLS from '../../urls';
import Button from '../../common/components/Button';
import SendPDFModal from './SendPDFModal';
import Ajax from '../../common/ajax';
import Waiting from '../../common/containers/Waiting';
import ErrorBoundary from '../../common/containers/ErrorBoundary';
import { withNamespaces } from 'react-i18next';
import { AppContext } from '../../context/global';
import Row from '../../common/containers/Row';
import Col from '../../common/containers/Col';
import CopyDocumentButton from './CopyDocumentButton';
import LazyImage from '../../common/components/LazyImage';
import ExportToDocx from './ExportToDocx';

class Document extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processingRevert: false,
      processingRemovePermanently: false,
      documentProcessing: false,
      showSendPDF: false,
      document: null,
    };
    this.handleRevertClick = this.handleRevertClick.bind(this);
    this.handleRemovePermanentlyClick = this.handleRemovePermanentlyClick.bind(this);
    this.handleShowSendPDFClick = this.handleShowSendPDFClick.bind(this);
    this.onSendPDFHide = this.onSendPDFHide.bind(this);
  }

  componentDidMount() {
    if (this.props.documentId) {
      this.fetchDocument();
    } else {
      this.setState({
        ...this.state,
        document: this.props.document,
      });
    }
  }

  handleRevertClick() {
    this.setState((state) => ({ ...state, processingRevert: true }));
    this.props.handleRevertTrashClick(this.state.document.id);
  }

  handleRemovePermanentlyClick() {
    const confirmed = this.props.handleRemovePermanentlyClick(this.state.document.id);
    if (confirmed) {
      this.setState((state) => {
        return { ...state, processingRemovePermanently: true };
      });
    }
  }

  handleShowSendPDFClick() {
    this.setState((state) => ({
      ...state,
      showSendPDF: true,
    }));
  }

  fetchDocument() {
    this.setState((state) => ({
      ...state,
      documentProcessing: true,
    }));
    const fields = [
      'id',
      'title',
      'last_modification_date',
      'template_meta__name',
      'template_meta__type',
      'reference_count',
      'page_count',
      'authors_comma_separated',
      'total_revisions',
      'figure_count',
      'is_presentation',
    ];
    const url = `${Config.apiHost}documents/${this.props.documentId}`;
    Ajax.get(url, fields).done((document) => {
      this.setState({
        ...this.state,
        document,
        documentProcessing: false,
      });
    });
  }

  onSendPDFHide() {
    this.setState({
      ...this.state,
      showSendPDF: false,
    });
  }

  getDocumentEditLink() {
    const { i18n } = this.props;

    const docTitle =
      (this.state.document.title && HtmlUtils.stripHtmlTags(this.state.document.title)) ||
      i18n.t('Documento sem título');

    return (
      /**
       * Changed to a normal link because the router <Link> was cousing confusing the loading of CKEDITOR
       * when changing  between document and presentation. This will for the CKEDITOR to fully reload due
       * to the full page reload.
       */
      <a href={URLS.documentEdit(this.state.document.id)}>
        <span style={{ wordWrap: 'break-word' }}>{docTitle}</span>
      </a>
    );
  }

  renderPanelHeading() {
    if (!this.state.document) {
      return null;
    }

    const { i18n } = this.props;

    return (
      <div className="panel-heading">
        <span className="text-muted" style={{ fontSize: '11pt' }}>
          {this.state.document.is_presentation ? (
            <>
              <i className="mdi mdi-slideshow" /> {i18n.t('Apresentação')} nº {this.state.document.id}
            </>
          ) : (
            <>
              <i className="far fa-file" /> {i18n.t('Documento')} nº {this.state.document.id}
            </>
          )}
        </span>
        <br />
        {this.getDocumentEditLink()}
        {!this.props.isInTrash && (
          <div className="tools dropdown">
            <a href="#" type="button" data-toggle="dropdown" className="dropdown-toggle">
              <i className="icon mdi mdi-more-vert" />
            </a>
            <ul role="menu" className="dropdown-menu pull-right">
              <li>
                <a role="button" title="E-mail PDF" onClick={this.handleShowSendPDFClick}>
                  <i className="mdi mdi-mail-send" />
                  &nbsp;
                  {i18n.t('Enviar') + ' PDF'}
                </a>
              </li>
              {this.props.onCopy && (
                <li>
                  <CopyDocumentButton document={this.state.document} onCopy={this.props.onCopy} />
                </li>
              )}
              <li>
                <Link to={URLS.documentHistory(this.state.document.id)} title={i18n.t('Histórico')}>
                  <i className="mdi mdi-time-restore" />
                  &nbsp;
                  {i18n.t('Histórico')}
                </Link>
              </li>
              <li>
                <Link to={URLS.documentRevisions(this.state.document.id)} title={i18n.t('Revisões')}>
                  <i className="mdi mdi-comment" />
                  &nbsp;
                  {i18n.t('Revisões')}
                </Link>
              </li>
              <li className="divider" />
              {this.props.handleRemoveClick && (
                <li>
                  <a
                    role="button"
                    onClick={() => this.props.handleRemoveClick(this.state.document.id)}
                    className="ff-document-delete-button"
                  >
                    <i className="mdi mdi-delete"> </i>
                    &nbsp;
                    {i18n.t('Remover')}
                  </a>
                </li>
              )}
            </ul>
          </div>
        )}
        <div className="panel-subtitle">
          <Row>
            <Col md={12} style={{ fontSize: '11pt' }}>
              <i>{this.state.document.authors_comma_separated}</i>
              <span style={{ wordWrap: 'break-word' }} className="text-muted">
                &mdash;
                {this.state.document.template_meta.name}
              </span>
            </Col>
          </Row>
          <Row style={{ marginTop: '5px' }}>
            <Col md={12}>
              {this.state.document.page_count > 0 && (
                <span className="text-muted">
                  <i className="mdi mdi-collection-text" />
                  &nbsp;
                  {this.state.document.page_count}{' '}
                  {this.state.document.page_count > 1 ? i18n.t('Páginas') : i18n.t('Página')}
                </span>
              )}
              {this.state.document.reference_count > 0 && (
                <span className="text-muted" style={{ marginLeft: '8px' }}>
                  <i className="mdi mdi-bookmark" />
                  &nbsp;
                  {this.state.document.reference_count}{' '}
                  {this.state.document.reference_count > 1 ? i18n.t('Referências') : i18n.t('Referência')}
                </span>
              )}
              {this.state.document.figure_count > 0 && (
                <span className="text-muted" style={{ marginLeft: '8px' }}>
                  <i className="mdi mdi-image" />
                  &nbsp;
                  {this.state.document.figure_count}{' '}
                  {this.state.document.figure_count > 1 ? i18n.t('Imagens') : i18n.t('Imagem')}
                </span>
              )}
              <span className="text-muted" style={{ marginLeft: '8px' }}>
                <i className="mdi mdi-time-interval" />
                &nbsp;
                {i18n.t('Alterado')}
                &nbsp;
                {LocaleUtils.fromNow(this.state.document.last_modification_date)}
              </span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getEditButton() {
    const { i18n } = this.props;
    return (
      <Link
        to={URLS.documentEdit(this.state.document.id)}
        title={i18n.t('Editar o documento')}
        className="btn btn-link"
      >
        <i className="icon mdi mdi-edit" />
        &nbsp;
        {i18n.t('Editar')}
      </Link>
    );
  }

  renderPanelBody() {
    if (!this.state.document) {
      return null;
    }

    const { i18n } = this.props;

    return (
      <div className="panel-body">
        {this.getEditButton()}

        <ShareButton document={this.state.document} />

        <Link
          to={URLS.documentHistory(this.state.document.id)}
          title={i18n.t('Visualizar histórico de modificações')}
          className="btn btn-link"
        >
          <i className="icon mdi mdi-time-restore" />
          &nbsp;
          {i18n.t('Histórico')}
        </Link>

        <Link
          to={URLS.documentRevisions(this.state.document.id)}
          title={i18n.t('Gerenciar revisões do documento')}
          className="btn btn-link"
        >
          <i className="icon mdi mdi-comment-edit" />
          &nbsp;
          {i18n.t('Revisões')}
          &nbsp;
          {this.state.document.total_revisions > 0 && <span>({this.state.document.total_revisions})</span>}
        </Link>

        <ExportToDocx />

        {this.props.children}
      </div>
    );
  }

  renderPanelBodyTrash() {
    if (!this.state.document) {
      return null;
    }

    const { i18n } = this.props;

    return (
      <div className="panel-body">
        <Button type="link" processing={this.state.processingRevert} onClick={this.handleRevertClick}>
          <i className="mdi mdi-undo" />
          &nbsp;
          {i18n.t('Reverter')}
        </Button>

        <Button
          type="link"
          processing={this.state.processingRemovePermanently}
          onClick={this.handleRemovePermanentlyClick}
        >
          <i className="mdi mdi-delete" />
          &nbsp;
          {i18n.t('Remover permanentemente')}
        </Button>
      </div>
    );
  }

  renderPanelContent() {
    return (
      <>
        {this.renderPanelHeading()}
        {!this.props.isInTrash && this.renderPanelBody()}
        {this.props.isInTrash && this.renderPanelBodyTrash()}
      </>
    );
  }

  render() {
    const isPresentation = this.state.document && this.state.document.is_presentation;

    return (
      <ErrorBoundary>
        <div className="panel">
          <Waiting isProcessing={this.state.documentProcessing}>
            {isPresentation && (
              <Row>
                <Col md={2} style={{ paddingRight: '0px' }}>
                  {this.state.document && this.state.document.cover_thumbnail && (
                    <div className="thumbnail" style={{ marginTop: '25px', marginLeft: '20px' }}>
                      <Link to={URLS.documentEdit(this.state.document.id)}>
                        <LazyImage
                          use43Ratio={true}
                          src={`${Config.djangoHost}${this.state.document.cover_thumbnail.url}`}
                          alt="Cover"
                        />
                      </Link>
                    </div>
                  )}
                </Col>
                <Col md={10} style={{ paddingLeft: '0px' }}>
                  {this.renderPanelContent()}
                </Col>
              </Row>
            )}

            {!isPresentation && this.renderPanelContent()}

            {this.state.document && (
              <SendPDFModal
                document={this.state.document}
                onCancel={this.onSendPDFHide}
                show={this.state.showSendPDF}
              />
            )}
          </Waiting>
        </div>
      </ErrorBoundary>
    );
  }
}

Document.propTypes = {
  /**
   * Document object.
   */
  document: PropTypes.object,
  /**
   * Document ID. If provided, the document will be fetched from server.
   */
  documentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleRemoveClick: PropTypes.func,
  handleRemovePermanentlyClick: PropTypes.func,
  handleRevertTrashClick: PropTypes.func,
  onCopy: PropTypes.func,
  isInTrash: PropTypes.bool.isRequired,
};

Document.contextType = AppContext;

export default withNamespaces()(Document);
